/* .weatherforecasttableMain {
  margin-top: 2rem;
} */
.gridRow3 {
  grid-row: 3 !important;
}
.weatherprognoseTable1 {
  display: grid;
}
.weatherprognoseTable_div {
  /* max-height: '400px'; */
  overflow: auto;
  position: relative;
  max-height: 500px;
  max-width: 100%;
  /* width: 1200px; */
}
.weatherprognoseTable {
  transition: all 0.3s ease;
  max-width: 100%;
  /* width: 1200px; */
  max-height: 660px;
  overflow: auto;
  /* padding-right: 1rem; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}
/* 
.styleIconDiv {
  width: fit-content;
  background-color: #a92a4e;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1px;
} */
.yellow {
  background-color: #ffdb23 !important;
}

.orange {
  background-color: #ff9500 !important;
}

.red {
  background-color: #ff0100 !important;
}
/* .styleIconDiv svg {
  filter: invert(1);
} */
.weatherprognoseTable_table {
  font: normal normal normal 20px/32px 'SourceSans';
  width: 1600px;
  /* margin-top: 20px; */
  border-collapse: collapse;
  /* margin-top: 20px; */
  font-size: 1.1rem;
  color: #052e37;
  table-layout: fixed;
}
.weatherprognoseTable_table_wind {
  /* width: 2000px; */
  table-layout: auto;
  /* width: max-content; */
}
.weatherprognoseTable_table tr {
  height: 60px;
}

.weatherprognoseTable_table th {
  background-color: #ffffff;
  font: normal normal normal 20px/32px 'SourceSans';
}
.weatherprognoseTable_table .iconTemperatur {
  display: flex;
  align-items: center;
  /* gap: 0.5rem; */
  justify-content: space-evenly;
}
.iconTemperatur .iconTemperaturValue {
  display: flex;
  gap: 5px;
  /* border: 1px solid #bfce40; */
  align-items: center;
}

.iconTemperaturValue .iconTemperatur-text-left {
  border-right: 1px solid #bfce40;
}
/* .weatherprognoseTable_table td {
  display: flex;
} */
.styleIconDiv {
  width: fit-content;
  /* background-color: #a92a4e; */
  justify-content: center;
  align-items: center;
  display: flex;
  /* padding: 7px; */
  /* border-radius: 50%; */
}

.weatherforecastTableTemp {
  display: flex;
  gap: 0.2rem;
}
.weatherforecastTableBorderColor {
  color: #bfce40;
}
.weatherprognoseTable_table tr:nth-child(odd) {
  background-color: #f3f5f5;
}
/* Styling for odd rows */
/* .weatherprognoseTable_table td:nth-child(odd),
.weatherprognoseTable_table th:nth-child(odd) {
  background-color: #f2f2f2;
} */
.thFlex {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.flexCol {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  white-space: nowrap;
  align-items: flex-start;
  /* line-height: 0; */
}
.flexCol span {
  line-height: 19px;
}
.weatherprognoseTable_table tr {
  font: normal normal normal 20px/32px 'SourceSans';
}

.weatherprognoseTable_table th,
.weatherprognoseTable_table td {
  border-right: 1px solid #9babaf;

  padding: 8px;

  /* color: #052e37; */
  /* text-align: center; */
  /* text-align: left; */
}
.paddingSmall {
  padding: 8px 0 !important;
}

.weatherprognoseTable_table th {
  text-align: left;
  font: normal normal bold 20px/32px 'SourceSans';
  letter-spacing: 0px;

  opacity: 1;
  border-right: 1px solid #9babaf;
}
.fontBold {
  font-weight: bold !important;
}

.weatherprognoseTable_table .vorhersageCenter {
  text-align: center;
}
.weatherprognoseTable_table td:last-child,
.weatherprognoseTable_table th:last-child {
  border-right: none;
  margin-right: 0.5rem;
}
.divInputSearch {
  max-width: 333px;
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: #052e37 1px solid;
  padding: 20px 17px 18px 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  gap: 0.5rem;
  cursor: pointer;
  /* margin-bottom: 2rem; */
  margin: 40px 0;
}

.inputSearch {
  border: none;
  font: normal normal normal 20px/32px 'SourceSans';
  height: 25px;
  width: 253px;
  cursor: pointer;
}
.inputSearch:focus {
  border: none;
  outline: none;
}

/* Styling for station names */
/* .weatherprognoseTable_table td:first-child {
  font-weight: bold;
} */

/* .table_mode {
  display: flex;
  /* position: absolute; */
/* bottom: 10px;
  left: 10px; */
/* padding: 10px; */
/* gap: 5px; */
/* }  */
/* .button_table_mode {
  position: relative;
  padding: 10px;
  background-color: #3498db; 
  color: #fff; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
} */

/* .active {
  background-color: #1d699b; 
} */

/* hover effect */
/* Style for the table row when not hovered */
/* .weatherprognoseTable_table tr {
  transition: background-color 0.3s ease;
  background-color: #fbfeff;
} */

/* Style for the table row when hovered */

.weatherprognoseTable_table tbody tr:hover {
  color: #ffff;
  background-color: #37585f;
  cursor: pointer;
  /* border: black 2px solid; */
}
.weatherprognoseTable_table .active {
  color: #ffff;
  background-color: #37585f !important;
  /* border: black 2px solid; */
}
.active {
  color: #ffff;
  background-color: #37585f !important;
  /* border: black 2px solid; */
}

.table_modeParent {
  border-bottom: 3px solid #bfce40;
  margin-bottom: 60px;
}
.table_mode {
  gap: 20px;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}

.rotate-NOT-available {
  display: none;
}
.rotate-0 {
  transform: rotate(0deg);
}
.rotate-1 {
  transform: rotate(22.5deg);
}
.rotate-2 {
  transform: rotate(45deg);
}
.rotate-3 {
  transform: rotate(67.5deg);
}
.rotate-4 {
  transform: rotate(90deg);
}
.rotate-5 {
  transform: rotate(112.5deg);
}
.rotate-6 {
  transform: rotate(135deg);
}
.rotate-7 {
  transform: rotate(157.5deg);
}
.rotate-8 {
  transform: rotate(180deg);
}
.rotate-9 {
  transform: rotate(202.5deg);
}
.rotate-10 {
  transform: rotate(225deg);
}
.rotate-11 {
  transform: rotate(247.5deg);
}
.rotate-12 {
  transform: rotate(270deg);
}
.rotate-13 {
  transform: rotate(292.5deg);
}
.rotate-14 {
  transform: rotate(315deg);
}
.rotate-15 {
  transform: rotate(337.5deg);
}

@media (max-width: 1640px) {
  .parentTableForecast {
    margin-right: 40px;
    margin-left: 40px;
  }
}

/* Responsive styling */
@media screen and (max-width: 600px) {
  .weatherprognoseTable_table th,
  .weatherprognoseTable_table td {
    padding: 5px;
  }

  /* Hide station names on small screens */
  /* .weatherprognoseTable_table td:first-child,
  .weatherprognoseTable_table tr:first-child {
    display: none;
  } */

  /* Display station names as headers on small screens */
  .weatherprognoseTable_table th:first-child {
    display: table-cell;
  }
}
