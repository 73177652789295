.headerMeassurement {
  display: flex;
  margin: 69px 0 36px 0;
}
.headerMeassurement h2 {
  margin: 0 !important;
  padding: 0 !important;
  font: normal normal bold 34px/48px 'WeissenhofGrotesk';
}

.measurement {
  /* Styles for the main container */
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f3f5f5;
  font: normal normal normal 20px/30px 'SourceSans';
  color: #052e37;
}

.measurement__value {
  /* Styles for the value and unit container */
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  font: normal normal bold 20px/30px 'SourceSans';
}

.slash {
  margin-left: 1rem;
  margin-right: 1rem;
  color: #bfce40;
}

.seperaterItem {
  display: flex;
  flex-wrap: wrap;
}
.flexTimeLine {
  display: flex;
}

@media (max-width: 1000px) {
  /* Example media query for smaller screens */
  .ParamaterLabel {
    display: none;
  }
  .measurement {
    flex-direction: column;
    align-items: baseline;
  }
}
.marginSettings {
  margin-top: -30px;
  margin-bottom: 100px;
}
@media (max-width: 1640px) {
  .marginSettings {
    margin-right: 40px;
    margin-left: 40px;
  }
}
