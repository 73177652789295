#page-document .blocks-group-wrapper > .block.form {
    max-width: $default-container-width;

    button.button[type="submit"] {
        background: $midnight-green;
        height: 60px;
        font-size: 20px;
        font-weight: normal;
        display: block;
        border-radius: 0;
        padding: 17px 20px;
        transition: background-color 100ms, color 100ms;

        &:focus,
        &:hover {
          background-color: $lime;
          color: $midnight-green;
        }
    }

    form > .grid {
        max-width: 100%;
        gap: $block-vertical-space 0!important;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .inline.field .wrapper {
        margin-top: 0;
        min-height: 0;
        padding-bottom: 15px;
    }

    .error.field {
        .ui.input input {
            border: 3px solid $red!important;
        }
        label {
            color: $red!important;
        }
    }

    .help {
        padding: 0;
        padding-top: 4px;
    }

    .column {
        width: 100%!important;

        > div.field > div {
            &.required label::after {
                content: none;
            }
            &:not(.required) label::after {
                content: none;
            }
        }
    }

    .ui.input.date-input, .DateInput, .DateInput_input {
        width: 100%!important;
    }

    .public-ui {

        .segment {
            border: none;
            box-shadow: none;
            padding: 0;

            .ui.grid {
                .row {
                    flex-direction: column;
                }
                .column {
                    .ui.input input, textarea {
                        border: 1px solid $midnight-green-30;
                        border-radius: 5px;
                        padding: 10px 20px;
                        font-size: 20px;
                        margin-top: 0;
                    }
                    .ui.input {
                        input:focus {
                            border: 3px solid $midnight-green;
                        }
                    }
                }

                p.help {
                    font-size: 16px;
                    border: 0;
                    color: $midnight-green;
                }
                label {
                    font-size: 20px;
                    color: $midnight-green;
                }
                .wrapper {
                    border: none;
                }
                .field, .column {
                    padding: 0;
                }
            }
        }
    }
}

.ui.message {
    padding: 60px 80px;
    margin: 0!important;
    width: 100%!important;
    align-items: flex-start!important;

    &.error {
        background-color: $red-10;
    }

    &.positive {
        background-color: $lime-20;

        .ui.secondary.button {
            display: none;
        }
    }


    .header {
        font-size: 26px!important;
        margin-bottom: 30px;
    }

    p {
        font-size: 20px;
        color: $midnight-green;
    }
}
.ui.form .ui.basic.red.label.form-error-label {
    background: $red!important;
    color: $white!important;
    font-size: 16px;
    padding: 7px;
    margin-top: 10px;
    border-radius: 0;

    &::before {
        content: "!";
        padding-right: 10px;
        padding-left: 5px;
        border-right: 1px solid #fff;
        margin-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@container (max-width: 1000px) {
    #page-document .blocks-group-wrapper > .block.form {
        margin-right: 40px;
        margin-left: 40px;
    }
}
